<script lang="ts" setup>
import { TooltipArrow, TooltipContent, TooltipPortal, TooltipProvider, TooltipRoot, TooltipTrigger } from 'radix-vue'

defineOptions({
  name: 'MultiAreaPill',
})

const props = withDefaults(
  defineProps<{
    /**
     * The areas where the ticket is valid
     * @example ['Area 1', 'Area 2']
     */
    areas: string[]
    /**
     * set to true to show the pill in a box
     */
    boxed?: boolean
  }>(),
  {
    boxed: false,
  },
)
</script>

<template>
  <div class="position-relative flex items-center gap-2" :class="props.boxed ? 'w-fit border border-1 border-gray-75 rounded-lg p-2' : ''">
    <div class="flex rounded" :class="props.boxed ? 'bg-gray-400 p-1' : ''">
      <span class="i-lucide:map-pin" :class="props.boxed ? 'text-white' : 'text-primary-500 text-xl'" />
    </div>
    <span :class="props.boxed ? 'font-medium leading-5' : 'text-sm'">{{ props.areas[0] }}</span>
    <TooltipProvider v-if="props.areas.length > 1" :delay-duration="150">
      <TooltipRoot>
        <TooltipTrigger class="position-relative mr-4 bg-transparent">
          <span class="position-absolute inline-block h-full w-full border rounded-full bg-primary-300" />
          <span class="position-absolute left-2 inline-block h-full w-full border rounded-full bg-primary-400" />
          <span class="position-relative left-4 inline-flex items-center gap-2 whitespace-nowrap border rounded-full bg-primary-500 px-2 py-1 text-3 text-white font-bold">
            + {{ props.areas.length - 1 }}
          </span>
        </TooltipTrigger>
        <TooltipPortal>
          <TooltipContent
            class="SlideDownAndFade will-change-[transform,opacity] select-none border border-primary-400 rounded-lg bg-white p-1 leading-none shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px]"
            :side-offset="5"
          >
            <div class="max-h-40 flex flex-col gap-2 overflow-y-auto p-2">
              <div v-for="area in props.areas.slice(1)" :key="area" class="flex items-center gap-2">
                <div class="i-lucide:map-pin text-xl text-primary-500" />
                <span>{{ area }}</span>
              </div>
            </div>
            <TooltipArrow
              class="fill-primary-400"
              :width="8"
            />
          </TooltipContent>
        </TooltipPortal>
      </TooltipRoot>
    </TooltipProvider>
  </div>
</template>
